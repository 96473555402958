import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Table, Badge } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import {
  userManagementList,
  blockUserApi,
  deleteUserApi,
  userKycApi,
} from "../components/APIs/Api";

import Pagination from "../components/Common/Pagination";
import Switch from "react-switch";
import S3Image from "../components/S3Image";
import rejectIcon from "../../images/decline (1).png";
import approveIcon from "../../images/approve.png";
import CustomTooltip from "../components/Tooltip";
import ConfirmationModal from "../modal/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Filter from "../components/Filter/Filter";
import {
  configFilterLabels,
  kycOptions,
  userTypeOptions,
} from "../components/Filter/FilterLable";
import { scrollToTop } from "../components/Utils/ScrollOnTop";
import XGourdLoader from "../components/XGourdLoader";
import RejectMessageModal from "../modal/RejectMessageModal";

const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), delay);
  };
};

export default function UserManagement() {
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState("");
  const [usersList, setUsersList] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [rejectConfirmationModal, setRejectConfirmationModal] = useState(false);
  const [filters, setFilters] = useState({ userType: "", kycType: "" });
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [filterTypes, setFilterTypes] = useState("active");

  const navigate = useNavigate();

  const handleSearch = useCallback(
    debounce((value) => {
      setSearch(value);
      setCurrentPage(0);
    }, 600),
    []
  );

  const fetchUserList = async () => {
    setLoader(true);
    try {
      const res = await userManagementList(
        currentPage,
        itemsPerPage,
        search,
        filters.kycType,
        filters.userType
      );
      setUsersList(res);
    } catch (err) {
      toast.error(err?.response?.data?.data || err?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  };

  const blockUser = async (id) => {
    setLoader(true);
    try {
      const res = await blockUserApi(id);
      fetchUserList();
      toast.success(res?.data?.message);
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  };
  async function handleApproveUser(id, status) {
    setLoader(true);
    try {
      const postData = {
        _id: id,
        status: status,
      };
      const response = await userKycApi(postData);
      toast.success("User Approved Successfully");
      fetchUserList();
    } catch (error) {
      toast.error(error);
    } finally {
      setLoader(false);
    }
  }

  function onSendItem(item) {
    navigate("/user-details", { state: item });
  }

  const totalItems = usersList?.data?.totalRecords;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    fetchUserList();
  }, [currentPage, search]);

  useEffect(() => {
    if (itemsPerPage * currentPage !== totalItems) {
      setCurrentPage(0);
    }
    fetchUserList();
    scrollToTop();
  }, [filters]);

  return (
    <>
      <PageTitle activeMenu="Users List" motherMenu="User Management" />
      <div className="pb-4 d-flex justify-content-between w-100">
        <div style={{ width: "40%" }}>
          <Filter searchType="search" setSearch={handleSearch} />
        </div>
        <div className="display-flex gap">
          <Filter
            filterType={filters.userType}
            setFilterType={(value) =>
              setFilters((prev) => ({ ...prev, userType: value }))
            }
            FilterOption={userTypeOptions}
            filter="filter"
            filterTitle="Select User . . ."
          />
          <Filter
            filterType={filters.kycType}
            setFilterType={(value) =>
              setFilters((prev) => ({ ...prev, kycType: value }))
            }
            FilterOption={kycOptions}
            filter="filter"
            filterTitle="KYC Status"
          />
        </div>
      </div>
      <div className="d-flex pb-4">
        <Filter
          tabs={true}
          FilterOption={configFilterLabels}
          filterType={filterTypes}
          setFilterType={setFilterTypes}
        />
      </div>

      <Col>
        {loader ? (
          <div className="text-center mt-5">
            <XGourdLoader />
          </div>
        ) : (
          <Card>
            <Card.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th>IMAGE</th>
                    <th>NAME</th>
                    <th>EMAIL</th>
                    <th>PHONE</th>
                    <th>KYC</th>
                    <th>STATUS</th>
                    <th className="text-center">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {usersList?.data?.data?.map((item) => (
                    <tr key={item?._id} className="table-scale">
                      <td onClick={() => onSendItem(item)}>
                        <S3Image imageKey={item?.image} style="profile-img" />
                      </td>
                      <td onClick={() => onSendItem(item)}>
                        {item?.name?.length > 15
                          ? `${item?.name.slice(0, 15)}...`
                          : item?.name || ""}
                      </td>
                      <td onClick={() => onSendItem(item)}>
                        {item?.email?.length > 20
                          ? `${item?.email.slice(0, 20)}...`
                          : item?.email}
                      </td>
                      <td onClick={() => onSendItem(item)}>{item?.number}</td>
                      <td>
                        <Badge
                          bg={
                            item?.kycStatus === "pending"
                              ? "info"
                              : item?.kycStatus === "rejected"
                              ? "danger"
                              : "success"
                          }
                        >
                          {item?.kycStatus === "pending"
                            ? "Pending"
                            : item?.kycStatus === "rejected"
                            ? "Rejected"
                            : "Approved"}
                        </Badge>
                      </td>
                      <td>
                        <Switch
                          onChange={() => blockUser(item?._id)}
                          checked={item.isBlocked}
                          onColor={"#FF0000"}
                          handleDiameter={30}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        />
                      </td>
                      <td>
                        <div className="display-flex gap">
                          <CustomTooltip content="Delete">
                            <div
                              className="btn btn-danger btn-xs sharp hover-item-transition display-flex"
                              onClick={() => {
                                setSelectedUsers(item);
                                setIsDeleteModal(true);
                              }}
                            >
                              <i className="fa fa-trash"></i>
                            </div>
                          </CustomTooltip>
                          {(item?.userType === "agency" ||
                            item?.userType === "guardian") && (
                            <>
                              {item?.kycStatus === "pending" && (
                                <>
                                  <CustomTooltip content="Reject">
                                    <div
                                      className="btn btn-danger btn-xs sharp hover-item-transition display-flex"
                                      onClick={() => {
                                        setSelectedUsers(item);
                                        setRejectConfirmationModal(true);
                                      }}
                                    >
                                      <img
                                        src={rejectIcon}
                                        height={35}
                                        alt="Reject"
                                      />
                                    </div>
                                  </CustomTooltip>
                                  <CustomTooltip content="Approve">
                                    <div
                                      className="btn btn-primary btn-xs sharp hover-item-transition display-flex"
                                      onClick={() => {
                                        handleApproveUser(
                                          item?._id,
                                          "accepted"
                                        );
                                      }}
                                    >
                                      <img
                                        src={approveIcon}
                                        height={30}
                                        alt="Approve"
                                      />
                                    </div>
                                  </CustomTooltip>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {usersList?.data?.data?.length === 0 && (
                <div className="text-center my-5">Sorry, Data Not Found!</div>
              )}
              {usersList?.data?.data?.length > 0 && (
                <div className="d-flex justify-content-between align-items-center">
                  <div className="dataTables_info">
                    Total Users{" "}
                    <span className="font-weight-bold">{totalItems} </span>
                  </div>
                  <Pagination
                    pageCount={totalPages}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div>
              )}
            </Card.Body>
          </Card>
        )}
      </Col>
      {isDeleteModal && (
        <ConfirmationModal
          show={isDeleteModal}
          onHide={() => setIsDeleteModal(false)}
          content={"Are you sure you want to Delete this User ?"}
          api={deleteUserApi}
          data={selectedUsers}
          refresh={fetchUserList}
          successMessage={"User Deleted Successfully"}
        />
      )}
      {rejectConfirmationModal && (
        <RejectMessageModal
          show={rejectConfirmationModal}
          onHide={() => setRejectConfirmationModal(false)}
          data={selectedUsers}
          refresh={fetchUserList}
        />
      )}
    </>
  );
}
