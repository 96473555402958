import React, { useState } from "react";
import "./UserDetails.css";
import { useLocation } from "react-router-dom";
import S3Image from "../components/S3Image";

function UserDetails() {
  const [activeTab, setActiveTab] = useState("Documents");
  const location = useLocation();
  const userData = location?.state;

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="user-details">
      <div className="user-header">
        <div>
          <S3Image imageKey={userData?.image} style={"user-image"} />
          <div className="agency-profile">
            <h5>Agency Profile</h5>
            <div className="work-item mt-4">
              <strong>Address</strong>
              <p>{userData?.agencyProfile?.address}</p>
            </div>
            <div className="work-item">
              <strong>Country</strong>
              <p>{userData?.agencyProfile?.country}</p>
            </div>
            <div className="work-item">
              <strong>Number of Employee</strong>
              <p>{userData?.agencyProfile?.numberOfEmp}</p>
            </div>
            <div className="work-item">
              <strong>Postal Code</strong>
              <p>{userData?.agencyProfile?.postalCode}</p>
            </div>
            <div className="work-item">
              <strong>Uniform Bio</strong>
              <p>{userData?.agencyProfile?.uniformDescription}</p>
            </div>
            <div className="work-item">
              <strong>Agency Bio</strong>
              <p>{userData?.agencyProfile?.agencyBio}</p>
            </div>
          </div>
        </div>
        <div className="user-info">
          <h2>{userData?.name}</h2>
          <p className="user-job-title">{userData?.userType}</p>
          <p className="user-location m-0">New York, NY</p>
          <div className="user-actions">
            <p className="m-0">
              <strong>KYC :</strong>{" "}
              <span>
                {" "}
                {userData?.kycStatus === "pending" ? (
                  <span class="badge-info light badge">Pending</span>
                ) : userData?.kycStatus === "rejected" ? (
                  <span class="badge-danger light badge">Suspended</span>
                ) : (
                  <span class="badge-success light badge">Suspended</span>
                )}
              </span>
            </p>
          </div>
          <div className="user-actions">
            <p className="m-0">
              <strong>Code:</strong> <span>84947</span>
            </p>
          </div>
          <div>
            <div className="profile-tabs">
              <p
                className={`tab-button ${
                  activeTab === "Documents" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Documents")}
              >
                Documents
              </p>
              <p
                className={`tab-button ${
                  activeTab === "About" ? "active" : ""
                }`}
                onClick={() => handleTabClick("About")}
              >
                About
              </p>
            </div>
            <div className="tab-content">
              {activeTab === "Documents" ? (
                <div className="document-section">
                  <h5>Documents</h5>
                  <div className="documents-images">
                    <div className="d-flex flex-column align-items-center">
                      <S3Image
                        imageKey={userData?.documents?.certificationDoc}
                      />
                      <strong className="m-0">Certification</strong>
                      <span>{userData?.documents?.certificationNumber}</span>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <S3Image
                        imageKey={userData?.documents?.drivingLicenceDoc}
                      />
                      <strong className="m-0">Driving License</strong>
                      <span>{userData?.documents?.drivingLicenceNumber}</span>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <S3Image
                        imageKey={userData?.documents?.officialBatchDoc}
                      />
                      <strong className="m-0">Official Batch</strong>
                      <span>{userData?.documents?.officialBatchNumber}</span>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <S3Image
                        imageKey={
                          userData?.documents?.securityGuaradClarification
                        }
                      />
                      <strong className="m-0">Guard Certification</strong>
                    </div>
                  </div>
                  <h5>Skills</h5>
                  <div className="documents-images">
                    <div className="">
                      <S3Image imageKey={userData?.skills?.gunLicenceDoc} />
                      <strong className="m-0 text-center">Gun License</strong>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="user-contact">
                  <div className="">
                    <h5>Profile</h5>
                    <p className="mt-3">
                      <strong>Start Time:</strong>{" "}
                      <span>{userData?.profile?.from || "N/A"}</span>
                    </p>
                    <p>
                      <strong>End Time:</strong>{" "}
                      <span>{userData?.profile?.to || "N/A"}</span>
                    </p>
                    <p>
                      <strong>Hourly Rate:</strong>{" "}
                      <span>{userData?.profile?.hourlyRate}</span>
                    </p>
                    <p>
                      <strong>Average Time:</strong>{" "}
                      <span>
                        {userData?.profile?.averageResponseTime || "N/A"}
                      </span>
                    </p>
                  </div>
                  <h5>Contact Information</h5>
                  <p>
                    <strong>Phone:</strong>{" "}
                    <strong>
                      {userData?.countryCode} - {userData?.number}
                    </strong>
                  </p>
                  <p>
                    <strong>Address:</strong>{" "}
                    <strong>525 E 68th Street, New York, NY</strong>
                  </p>
                  <p>
                    <strong>Email:</strong> <strong>{userData?.email}</strong>
                  </p>
                  {/* <p>
                    <strong>Site:</strong> <strong>www.jeremyrose.com</strong>
                  </p> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDetails;
