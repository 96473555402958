import React from "react";
import Lottie from "lottie-react";
import animationData from "../../images/loding.json";

export default function XGourdLoader() {
  return (
    <div>
      <Lottie
        style={{ height: "200px", marginTop: "45px", padding: "30px" }}
        animationData={animationData}
      />
    </div>
  );
}
