import React from "react";
import { Tooltip as BootstrapTooltip, OverlayTrigger } from "react-bootstrap";

function CustomTooltip({
  content,
  children,
  placement = "top",
  trigger = ["hover", "focus"],
  ...props
}) {
  const renderTooltip = (tooltipProps) => (
    <BootstrapTooltip id="tooltip" {...tooltipProps} {...props}>
      {content}
    </BootstrapTooltip>
  );

  return (
    <OverlayTrigger
      placement={placement}
      overlay={renderTooltip}
      trigger={trigger}
    >
      {children}
    </OverlayTrigger>
  );
}

export default CustomTooltip;
