import axios from "axios";
import axiosInstance from "../../../services/AxiosInstance";

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// ===================getAPIS=============================
export async function dashboardApi() {
  const response = await axiosInstance.get("/admin/dashboard", {});
  return response;
}

export async function userManagementList(
  currentPage,
  itemsPerPage,
  search,
  kycType,
  userType
) {
  const response = await axiosInstance.get(
    `/admin/users?limit=${itemsPerPage}&page=${currentPage}&search=${search}&userType=${userType}&status=${kycType}`,
    {}
  );
  return response;
}

export async function blockUserApi(_id) {
  const response = await axiosInstance.put(
    `/admin/enableDisableUser?_id=${_id}`
  );
  return response;
}
export async function userKycApi(postData) {
  const response = await axiosInstance.put(`/admin/kycApproveReject`, postData);
  return response;
}

export async function deleteUserApi(data) {
  const response = await axiosInstance.delete(
    `/admin/deleteUser?_id=${data?._id}`
  );
  return response;
}

// Category Management

export async function getCategoryApi(currentPage, itemsPerPage, search) {
  const response = await axiosInstance.get(
    `/admin/categoryList?limit=${itemsPerPage}&page=${currentPage}&search=${search}`,
    {}
  );
  return response;
}
export async function addCategoryApi(data) {
  const response = await axiosInstance.post("/admin/category", data, {});
  return response;
}
export async function editCategoryApi(data) {
  const postData = { name: data.name, logo: data.logo };
  const response = await axiosInstance.put(
    `/admin/editCategory?_id=${data?._id}`,
    postData,
    {}
  );
  return response;
}
export async function deleteCategoryApi(data) {
  const response = await axiosInstance.delete(
    `/admin/deleteCategory?_id=${data?._id}`
  );
  return response;
}
