import React, { useState } from "react";
import { Dropdown, Modal, Spinner } from "react-bootstrap";
import AWS from "aws-sdk";

import toast from "react-hot-toast";

export default function AddCategoryModal({
  title,
  show,
  onHide,
  buttonTitle,
  api,
  refresh,
  successMessage,
  selectedCategory,
}) {
  const s3 = new AWS.S3();
  console.log(selectedCategory);
  const [name, setName] = useState(selectedCategory?.name);
  const [nameError, setNameError] = useState("");
  const [loader, setLoader] = useState(false);
  const [image, setImage] = useState([]);

  const handleUpload = async (file) => {
    if (!file) {
      console.error("No file selected");
      return;
    }

    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: file.name,
      Body: file,
    };

    try {
      const data = await s3.upload(params).promise();
      return data;
    } catch (error) {
      console.error("Upload failed:", error);
      throw error;
    }
  };

  async function handleAddFormSubmit(event) {
    event.preventDefault();

    if (name.trim().length === 0) {
      setNameError("Name is required");
      return;
    }
    setLoader(true);

    try {
      let categoryImage;
      if (image) {
        categoryImage = await handleUpload(image);
      }
      const postData = {
        name,
        logo: categoryImage.Key,
      };
      if (title === "Update Category") {
        postData._id = selectedCategory?._id;
      }
      const res = await api(postData);
      setLoader(false);
      refresh();
      onHide(false);
      toast.success(successMessage);
    } catch (e) {
      setLoader(false);
      console.log(e?.response);
      toast.error(e?.response?.data?.message);
    }
  }

  return (
    <Modal className="sortBy" show={show} onHide={onHide} centered>
      <Modal.Header>
        <h3 className="text-center m-0">{title}</h3>
      </Modal.Header>

      <form onSubmit={handleAddFormSubmit}>
        <div className="p-3">
          <label className="text-black font-w500">{"Image"}</label>
          <div className="contact-name form-group">
            <input
              type="file"
              className="form-control"
              accept=".jpg,.png,.jpeg"
              required="required"
              onChange={(e) => {
                setImage(e.target.files[0]);
              }}
            />
            {selectedCategory?.logo && <span>{selectedCategory?.logo}</span>}
          </div>
          <label className="text-black font-w500">{"Name"}</label>
          <div className="contact-name ">
            <input
              type="text"
              className="form-control"
              name="Date_Join"
              required="required"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setNameError();
              }}
              placeholder="Enter category name"
            />
            <span className="text-danger">{nameError}</span>
          </div>
        </div>

        <div className="p-3 d-flex justify-content-end">
          <button className="btn btn-sm btn-primary">
            {loader ? <Spinner animation="border" size="sm" /> : buttonTitle}
          </button>
        </div>
      </form>
    </Modal>
  );
}
